export const gallery = {
    CharacterComparator: {
        "v": 5,
        "in": [
            { "pos": [50, 130], "id": 0, "val": 1 },
            { "pos": [50, 160], "id": 1, "val": 1 },
            { "pos": [50, 190], "id": 2, "val": 1 },
            { "pos": [50, 220], "id": 3, "val": 0 },
            { "pos": [50, 250], "id": 4, "val": 1 },
            { "pos": [50, 280], "id": 13, "val": 0 },
            { "pos": [50, 310], "id": 14, "val": 1 },
            { "pos": [50, 360], "id": 15, "val": 1 },
            { "pos": [50, 390], "id": 16, "val": 0 },
            { "pos": [50, 420], "id": 17, "val": 1 },
            { "pos": [50, 450], "id": 18, "val": 0 },
            { "pos": [50, 480], "id": 19, "val": 1 },
            { "pos": [50, 510], "id": 20, "val": 0 },
            { "pos": [50, 540], "id": 21, "val": 1 },
        ],
        "out": [
            { "pos": [870, 310], "id": 5 },
            { "type": "ascii", "pos": [180, 60], "id": [6, 7, 8, 9, 10, 11, 12] },
            { "type": "ascii", "pos": [180, 620], "id": [22, 23, 24, 25, 26, 27, 28] },
        ],
        "gates": [
            { "type": "XOR", "pos": [360, 140], "in": [29, 30], "out": 31 },
            { "type": "XOR", "pos": [360, 200], "in": [32, 33], "out": 34 },
            { "type": "XOR", "pos": [360, 260], "in": [35, 36], "out": 37 },
            { "type": "XOR", "pos": [360, 320], "in": [38, 39], "out": 40 },
            { "type": "XOR", "pos": [360, 380], "in": [41, 42], "out": 43 },
            { "type": "XOR", "pos": [360, 440], "in": [44, 45], "out": 46 },
            { "type": "XOR", "pos": [360, 500], "in": [47, 48], "out": 49 },
            { "type": "OR", "pos": [470, 170], "in": [68, 69], "out": 70 },
            { "type": "OR", "pos": [500, 280], "in": [71, 72], "out": 73 },
            { "type": "OR", "pos": [490, 410], "in": [74, 75], "out": 76 },
            { "type": "OR", "pos": [630, 250], "in": [77, 78], "out": 79 },
            { "type": "OR", "pos": [630, 410], "in": [80, 81], "out": 82 },
            { "type": "OR", "pos": [730, 310], "in": [83, 84], "out": 85 },
            { "type": "NOT", "pos": [800, 310], "in": 50, "out": 51 },
        ],
        "wires": [
            [0, 6],
            [1, 7],
            [2, 8],
            [3, 9],
            [4, 10],
            [13, 11],
            [14, 12],
            [15, 22],
            [16, 23],
            [17, 24],
            [18, 25],
            [19, 26],
            [20, 27],
            [21, 28],
            [0, 29],
            [15, 30],
            [79, 83],
            [82, 84],
            [31, 68],
            [34, 69],
            [37, 71],
            [40, 72],
            [43, 74],
            [46, 75],
            [49, 81],
            [76, 80],
            [73, 78],
            [70, 77],
            [85, 50],
            [51, 5],
            [1, 32],
            [16, 33],
            [2, 35],
            [17, 36],
            [18, 39],
            [3, 38],
            [4, 41],
            [19, 42],
            [13, 44],
            [20, 45],
            [21, 48],
            [14, 47],
        ],
    },
    Counters: {
        "v": 5,
        "in": [
            { "type": "clock", "pos": [70, 40], "id": 0, "name": "bit 0", "period": 2000, "phase": 1000, "showLabel": false },
            { "type": "clock", "pos": [70, 80], "id": 10, "name": "bit 1", "period": 4000, "phase": 2000, "showLabel": false },
            { "type": "clock", "pos": [70, 120], "id": 23, "name": "bit 2", "period": 8000, "phase": 4000, "showLabel": false },
            { "type": "clock", "pos": [70, 160], "id": 24, "name": "bit 3", "period": 16000, "phase": 8000, "showLabel": false },
            { "type": "clock", "pos": [430, 30], "id": 5, "period": 500, "phase": 250, "showLabel": false },
            { "type": "clock", "pos": [430, 70], "id": 6, "period": 1000, "phase": 500, "showLabel": false },
            { "type": "clock", "pos": [430, 110], "id": 7, "period": 2000, "phase": 1000, "showLabel": false },
            { "type": "clock", "pos": [430, 150], "id": 8, "period": 4000, "phase": 2000, "showLabel": false },
            { "type": "clock", "pos": [430, 190], "id": 9, "period": 8000, "phase": 4000, "showLabel": false },
            { "type": "clock", "pos": [430, 230], "id": 11, "period": 16000, "phase": 8000, "showLabel": false },
            { "type": "clock", "pos": [430, 270], "id": 12, "period": 32000, "phase": 16000, "showLabel": false },
        ],
        "out": [
            { "type": "display", "bits": 4, "pos": [240, 90], "id": [1, 2, 3, 4] },
            { "type": "ascii", "pos": [580, 150], "id": [13, 14, 15, 16, 17, 18, 19] },
        ],
        "wires": [
            [0, 1], [24, 4], [23, 3], [10, 2], [5, 13],
            [6, 14], [7, 15], [8, 16], [9, 17], [11, 18], [12, 19],
        ],
    },
    AllGates: {
        "v": 5,
        "in": [
            { "pos": [40, 320], "id": 0, "val": 0 },
            { "pos": [40, 490], "id": 1, "val": 0 },
        ],
        "out": [
            { "pos": [400, 30], "id": 48 },
            { "pos": [400, 80], "id": 49 },
            { "pos": [400, 140], "id": 50 },
            { "pos": [400, 260], "id": 51 },
            { "pos": [400, 380], "id": 52 },
            { "pos": [400, 200], "id": 53 },
            { "pos": [400, 320], "id": 54 },
            { "pos": [400, 440], "id": 55 },
            { "pos": [400, 560], "id": 56 },
            { "pos": [400, 680], "id": 57 },
            { "pos": [400, 500], "id": 58 },
            { "pos": [400, 620], "id": 59 },
            { "pos": [400, 740], "id": 60 },
            { "pos": [400, 860], "id": 61 },
            { "pos": [400, 800], "id": 62 },
            { "pos": [400, 920], "id": 63 },
        ],
        "gates": [
            { "type": "NOT", "pos": [290, 80], "in": 2, "out": 3 },
            { "type": "BUF", "pos": [290, 30], "in": 4, "out": 5 },
            { "type": "AND", "pos": [290, 140], "in": [6, 7], "out": 8 },
            { "type": "OR", "pos": [290, 260], "in": [9, 10], "out": 11 },
            { "type": "XOR", "pos": [290, 380], "in": [12, 13], "out": 14 },
            { "type": "NAND", "pos": [290, 200], "in": [15, 16], "out": 17 },
            { "type": "NOR", "pos": [290, 320], "in": [18, 19], "out": 20 },
            { "type": "XNOR", "pos": [290, 440], "in": [21, 22], "out": 23 },
            { "type": "IMPLY", "pos": [290, 560], "in": [24, 25], "out": 26 },
            { "type": "RIMPLY", "pos": [290, 680], "in": [27, 28], "out": 29 },
            { "type": "NIMPLY", "pos": [290, 500], "in": [30, 31], "out": 32 },
            { "type": "RNIMPLY", "pos": [290, 620], "in": [33, 34], "out": 35 },
            { "type": "TXA", "pos": [290, 740], "in": [36, 37], "out": 38 },
            { "type": "TXB", "pos": [290, 860], "in": [39, 40], "out": 41 },
            { "type": "TXNA", "pos": [290, 800], "in": [42, 43], "out": 44 },
            { "type": "TXNB", "pos": [290, 920], "in": [45, 46], "out": 47 },
        ],
        "wires": [
            [0, 4],
            [0, 2],
            [0, 6],
            [0, 9],
            [0, 12],
            [0, 15],
            [0, 18],
            [0, 21],
            [0, 24],
            [0, 27],
            [0, 30],
            [0, 33],
            [0, 36],
            [0, 39],
            [0, 42],
            [0, 45],
            [1, 7],
            [1, 10],
            [1, 13],
            [1, 16],
            [1, 19],
            [1, 22],
            [1, 25],
            [1, 28],
            [1, 31],
            [1, 34],
            [1, 37],
            [1, 40],
            [1, 43],
            [1, 46],
            [5, 48],
            [3, 49],
            [8, 50],
            [11, 51],
            [14, 52],
            [17, 53],
            [20, 54],
            [23, 55],
            [26, 56],
            [29, 57],
            [32, 58],
            [35, 59],
            [38, 60],
            [41, 61],
            [44, 62],
            [47, 63],
        ],
    },
    SevenSegmentDisplay: {
        "v": 5,
        "opts": {
            "propagationDelay": 20,
        },
        "in": [
            { type: "clock", "pos": [90, 60], "orient": "s", "id": 21, "period": 16000, "phase": 8000 },
            { type: "clock", "pos": [130, 60], "orient": "s", "id": 22, "period": 8000, "phase": 4000 },
            { type: "clock", "pos": [170, 60], "orient": "s", "id": 23, "period": 4000, "phase": 2000 },
            { type: "clock", "pos": [210, 60], "orient": "s", "id": 24, "period": 2000, "phase": 1000 },
        ],
        "out": [
            { "type": "display", "bits": 4, "pos": [1320, 140], "id": [132, 133, 134, 135], "radix": 16 },
            { "type": "bar", "pos": [1330, 310], "id": 117, "display": "h" },
            { "type": "bar", "pos": [1270, 490], "id": 118, "display": "v" },
            { "type": "bar", "pos": [1390, 370], "id": 119, "display": "v" },
            { "type": "bar", "pos": [1270, 370], "id": 120, "display": "v" },
            { "type": "bar", "pos": [1330, 430], "id": 121, "display": "h" },
            { "type": "bar", "pos": [1390, 490], "id": 122, "display": "v" },
            { "type": "bar", "pos": [1330, 550], "id": 123, "display": "h" },
        ],
        "gates": [
            { "type": "NOT", "pos": [1170, 310], "in": 103, "out": 104 },
            { "type": "NOT", "pos": [1170, 350], "in": 105, "out": 106 },
            { "type": "NOT", "pos": [1170, 390], "in": 107, "out": 108 },
            { "type": "NOT", "pos": [1170, 430], "in": 109, "out": 110 },
            { "type": "NOT", "pos": [1170, 550], "in": 111, "out": 112 },
            { "type": "NOT", "pos": [1170, 510], "in": 113, "out": 114 },
            { "type": "NOT", "pos": [1170, 470], "in": 115, "out": 116 },
            { "type": "XOR", "pos": [210, 180], "in": [0, 1], "out": 2 },
            { "type": "AND", "pos": [220, 340], "in": [3, 4], "out": 5 },
            { "type": "XOR", "pos": [200, 420], "in": [6, 7], "out": 8 },
            { "type": "XOR", "pos": [330, 220], "in": [9, 10], "out": 11 },
            { "type": "XOR", "pos": [340, 280], "in": [12, 13], "out": 14 },
            { "type": "OR", "pos": [320, 480], "in": [15, 16], "out": 17 },
            { "type": "NOR", "pos": [440, 220], "in": [18, 19], "out": 20 },
            { "type": "XOR", "pos": [450, 290], "in": [25, 26], "out": 27 },
            { "type": "NOR", "pos": [430, 450], "in": [31, 32], "out": 33 },
            { "type": "NOR", "pos": [560, 410], "in": [34, 35], "out": 36 },
            { "type": "NOR", "pos": [600, 190], "in": [37, 38], "out": 39 },
            { "type": "NOR", "pos": [540, 360], "in": [40, 41], "out": 42 },
            { "type": "XOR", "pos": [650, 320], "in": [43, 44], "out": 45 },
            { "type": "OR", "pos": [670, 430], "in": [46, 47], "out": 48 },
            { "type": "XOR", "pos": [770, 490], "in": [49, 50], "out": 51 },
            { "type": "NOR", "pos": [770, 540], "in": [52, 53], "out": 54 },
            { "type": "NOR", "pos": [800, 390], "in": [55, 56], "out": 57 },
            { "type": "OR", "pos": [960, 250], "in": [58, 59], "out": 60 },
            { "type": "NOR", "pos": [940, 310], "in": [61, 62], "out": 63 },
            { "type": "AND", "pos": [1050, 270], "in": [64, 65], "out": 66 },
            { "type": "AND", "pos": [950, 380], "in": [67, 68], "out": 69 },
            { "type": "AND", "pos": [950, 440], "in": [70, 71], "out": 72 },
            { "type": "OR", "pos": [1030, 410], "in": [73, 74], "out": 75 },
            { "type": "XOR", "pos": [1030, 520], "in": [76, 77], "out": 78 },
        ],
        "wires": [
            [104, 117],
            [106, 119],
            [108, 122],
            [110, 123],
            [112, 121],
            [114, 120],
            [116, 118],
            [21, 135],
            [22, 134],
            [23, 133],
            [24, 132],
            [21, 0],
            [23, 1],
            [24, 3],
            [22, 4],
            [24, 6],
            [21, 7],
            [2, 9],
            [22, 10],
            [23, 12],
            [5, 13],
            [5, 15],
            [8, 16],
            [11, 18],
            [14, 19],
            [11, 25],
            [8, 26],
            [5, 32],
            [14, 31],
            [33, 34],
            [21, 35],
            [20, 37],
            [2, 38],
            [27, 40],
            [8, 41],
            [42, 43],
            [21, 44],
            [39, 46],
            [17, 47],
            [48, 49],
            [21, 50],
            [48, 52],
            [33, 53],
            [36, 56],
            [45, 55],
            [57, 103],
            [39, 58],
            [24, 59],
            [60, 64],
            [27, 65],
            [66, 109],
            [39, 61],
            [45, 62],
            [63, 113],
            [69, 73],
            [72, 74],
            [75, 105],
            [39, 67],
            [24, 68],
            [45, 70],
            [22, 71],
            [51, 115],
            [20, 111],
            [78, 107],
            [72, 76],
            [54, 77],
        ],
    },
    SevenSegmentBCDDisplay: {
        "v": 5,
        "in": [
            { "bits": 4, "pos": [50, 80], "id": [0, 1, 2, 3], "val": [0, 1, 0, 1] },
        ],
        "out": [
            { "type": "bar", "pos": [560, 100], "id": 4, "display": "h" },
            { "type": "bar", "pos": [620, 280], "id": 5, "display": "v" },
            { "type": "bar", "pos": [500, 160], "id": 6, "display": "v" },
            { "type": "bar", "pos": [560, 220], "id": 7, "display": "h" },
            { "type": "bar", "pos": [560, 340], "id": 8, "display": "h" },
            { "type": "bar", "pos": [500, 280], "id": 9, "display": "v" },
            { "type": "bar", "pos": [620, 160], "id": 10, "display": "v" },
            { "type": "bar", "pos": [420, 160], "id": 50, "display": "v" },
            { "type": "bar", "pos": [420, 280], "id": 51, "display": "v" },
            { "type": "display", "bits": 4, "pos": [170, 250], "id": [11, 23, 24, 25] },
        ],
        "ic": [
            { "type": "decoder-7seg", "pos": [280, 60], "in": [12, 13, 14, 15], "out": [16, 17, 18, 19, 20, 21, 22] },
            { "type": "decoder-bcd4", "pos": [180, 80], "in": [26, 27, 28, 29], "out": [30, 31, 32, 33, 34] },
        ],
        "wires": [
            [16, 4], [17, 10], [18, 5], [19, 8], [20, 9], [21, 6], [22, 7], [0, 11],
            [1, 23], [2, 24], [3, 25], [0, 26], [1, 27], [2, 28], [3, 29], [30, 12],
            [31, 13], [32, 14], [33, 15], [34, 50], [34, 51],
        ],
    },
    SixteenVsSevenSegmentDisplays: {
        "v": 5,
        "in": [
            { "bits": 4, "pos": [50, 90], "id": [74, 75, 76, 77], "val": [1, 0, 0, 1] },
            { "bits": 4, "pos": [50, 170], "id": [78, 79, 80, 81], "val": [1, 1, 0, 0] },
        ],
        "out": [
            { "type": "16seg", "pos": [440, 170], "id": [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73] },
            { "type": "ascii", "pos": [230, 60], "id": [82, 83, 84, 85, 86, 87, 88], "additionalReprRadix": 10 },
            { "type": "7seg", "pos": [440, 330], "id": [93, 94, 95, 96, 97, 98, 99, 100] },
        ],
        "gates": [{ "type": "OR", "pos": [330, 250], "in": [90, 91], "out": 92 }],
        "ic": [
            { "type": "decoder-16seg", "pos": [230, 170], "in": [34, 35, 36, 37, 38, 39, 40], "out": [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 89] },
            { "type": "decoder-7seg", "pos": [230, 320], "in": [101, 102, 103, 104], "out": [105, 106, 107, 108, 109, 110, 111] },
        ],
        "wires": [
            [74, 82], [75, 83], [76, 84], [77, 85], [78, 86], [79, 87], [80, 88], [41, 57], [42, 58],
            [43, 59], [44, 60], [45, 61], [46, 62], [47, 63], [48, 64], [49, 65], [50, 66], [51, 67],
            [52, 68], [53, 69], [54, 70], [55, 71], [56, 72], [74, 34], [75, 35], [76, 36], [77, 37],
            [78, 38], [79, 39], [80, 40], [89, 90], [92, 73], [81, 91], [105, 93], [106, 94], [107, 95],
            [108, 96], [109, 97], [110, 98], [111, 99], [74, 101], [75, 102], [76, 103], [77, 104],
            [81, 100, { "via": [[200, 370]] }],
        ],
    },
    NibbleAdder: {
        "v": 5,
        "in": [
            { "pos": [100, 40], "orient": "s", "id": 3, "name": "A3", "val": 0 },
            { "pos": [200, 40], "orient": "s", "id": 0, "name": "A2", "val": 0 },
            { "pos": [300, 40], "orient": "s", "id": 1, "name": "A1", "val": 0 },
            { "pos": [400, 40], "orient": "s", "id": 2, "name": "A0", "val": 0 },
            { "pos": [140, 180], "orient": "s", "id": 15, "name": "B3", "val": 0 },
            { "pos": [240, 180], "orient": "s", "id": 14, "name": "B2", "val": 0 },
            { "pos": [340, 180], "orient": "s", "id": 13, "name": "B1", "val": 0 },
            { "pos": [440, 180], "orient": "s", "id": 12, "name": "B0", "val": 0 },
        ],
        "out": [
            { "pos": [30, 450], "orient": "s", "id": 37, "name": "S4" },
            { "pos": [120, 450], "orient": "s", "id": 36, "name": "S3" },
            { "pos": [220, 450], "orient": "s", "id": 38, "name": "S2" },
            { "pos": [320, 450], "orient": "s", "id": 39, "name": "S1" },
            { "pos": [420, 450], "orient": "s", "id": 40, "name": "S0" },
            { "type": "display", "bits": 4, "pos": [530, 100], "id": [4, 5, 6, 7] },
            { "type": "display", "bits": 4, "pos": [530, 240], "id": [8, 9, 10, 11] },
            { "type": "display", "bits": 4, "pos": [530, 390], "id": [41, 42, 43, 44] },
        ],
        "ic": [
            { "type": "adder", "pos": [420, 320], "in": [16, 17, 18], "out": [19, 20] },
            { "type": "adder", "pos": [320, 320], "in": [21, 22, 23], "out": [24, 25] },
            { "type": "adder", "pos": [220, 320], "in": [26, 27, 28], "out": [29, 30] },
            { "type": "adder", "pos": [120, 320], "in": [31, 32, 33], "out": [34, 35] },
        ],
        "wires": [
            [12, 8],
            [13, 9],
            [14, 10],
            [15, 11],
            [2, 4],
            [1, 5],
            [0, 6],
            [3, 7],
            [20, 23],
            [25, 28],
            [30, 33],
            [2, 16],
            [12, 17],
            [1, 21],
            [13, 22],
            [0, 26],
            [14, 27],
            [3, 31],
            [15, 32],
            [19, 41],
            [24, 42],
            [29, 43],
            [34, 44],
            [35, 37],
            [34, 36],
            [29, 38],
            [24, 39],
            [19, 40],
        ],
    },
    ALU: {
        "v": 5,
        "in": [
            { "pos": [290, 60], "orient": "s", "id": 16, "name": "Op1", "val": 0 },
            { "pos": [330, 60], "orient": "s", "id": 17, "name": "Op0", "val": 0 },
            { "pos": [50, 140], "id": 18, "val": 0 },
            { "pos": [50, 160], "id": 19, "name": "A", "val": 0 },
            { "pos": [50, 180], "id": 20, "val": 0 },
            { "pos": [50, 200], "id": 21, "val": 0 },
            { "pos": [50, 240], "id": 26, "val": 0 },
            { "pos": [50, 260], "id": 27, "name": "B", "val": 0 },
            { "pos": [50, 280], "id": 28, "val": 0 },
            { "pos": [50, 300], "id": 29, "val": 0 },
        ],
        "out": [
            { "pos": [420, 320], "id": 38, "name": "Zero" },
            { "pos": [420, 350], "id": 39, "name": "oVerflow" },
            { "type": "display", "bits": 4, "pos": [110, 60], "orient": "n", "id": [22, 23, 24, 25], "name": "A (non signé)" },
            { "type": "display", "bits": 4, "pos": [110, 350], "orient": "s", "id": [30, 31, 32, 33], "name": "B (non signé)" },
            { "type": "display", "bits": 4, "pos": [420, 250], "id": [34, 35, 36, 37], "name": "Y (signé)", "radix": -10 },
            { "type": "display", "bits": 4, "pos": [420, 150], "id": [40, 41, 42, 43], "name": "Y (non signé)" },
            { "type": "display", "bits": 4, "pos": [210, 60], "orient": "n", "id": [44, 45, 46, 47], "name": "A (signé)", "radix": -10 },
            { "type": "display", "bits": 4, "pos": [210, 350], "orient": "s", "id": [48, 49, 50, 51], "name": "B (signé)", "radix": -10 },
        ],
        "ic": [
            { "type": "alu", "pos": [280, 220], "in": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], "out": [10, 11, 12, 13, 14, 15] },
        ],
        "wires": [
            [16, 9],
            [17, 8],
            [21, 3],
            [20, 2],
            [19, 1],
            [18, 0],
            [18, 22],
            [19, 23],
            [20, 24],
            [21, 25],
            [26, 4],
            [27, 5],
            [28, 6],
            [29, 7],
            [26, 30],
            [27, 31],
            [28, 32],
            [29, 33],
            [10, 34],
            [11, 35],
            [12, 36],
            [13, 37],
            [15, 38],
            [14, 39],
            [10, 40],
            [11, 41],
            [12, 42],
            [13, 43],
            [18, 44],
            [19, 45],
            [20, 46],
            [21, 47],
            [26, 48],
            [27, 49],
            [28, 50],
            [29, 51],
        ],
    },

    MiniAlu: {
        "v": 5,
        "in": [
            { "pos": [340, 40], "orient": "s", "id": 10, "val": 1 },
            { "pos": [470, 40], "orient": "s", "id": 11, "val": 1 },
            { "pos": [370, 180], "orient": "s", "id": 12, "val": 0 },
            { "pos": [500, 180], "orient": "s", "id": 13, "val": 1 },
            { "pos": [80, 40], "orient": "s", "id": 26, "val": 0 },
            { "pos": [210, 40], "orient": "s", "id": 27, "val": 0 },
            { "pos": [110, 180], "orient": "s", "id": 28, "val": 0 },
            { "pos": [240, 180], "orient": "s", "id": 34, "val": 0 },
            { "pos": [600, 370], "orient": "w", "id": 98, "name": "Add/Sub", "val": 0 },
        ],
        "out": [
            { "pos": [600, 690], "id": 49, "name": "V" },
            { "type": "display", "bits": 4, "pos": [600, 120], "id": [18, 19, 20, 21], "name": "A" },
            { "type": "display", "bits": 4, "pos": [600, 260], "id": [22, 23, 24, 25], "name": "B" },
            { "type": "display", "bits": 4, "pos": [600, 620], "id": [45, 46, 47, 48], "name": "S" },
        ],
        "gates": [
            { "type": "XOR", "pos": [120, 450], "orient": "s", "in": [86, 87], "out": 88 },
            { "type": "XOR", "pos": [250, 450], "orient": "s", "in": [89, 90], "out": 91 },
            { "type": "XOR", "pos": [380, 450], "orient": "s", "in": [92, 93], "out": 94 },
            { "type": "XOR", "pos": [510, 450], "orient": "s", "in": [95, 96], "out": 97 },
        ],
        "ic": [
            { "type": "adder", "pos": [490, 530], "in": [0, 1, 2], "out": [3, 4] },
            { "type": "adder", "pos": [360, 530], "in": [5, 6, 7], "out": [8, 9] },
            { "type": "adder", "pos": [230, 530], "in": [35, 36, 37], "out": [38, 39] },
            { "type": "adder", "pos": [100, 530], "in": [40, 41, 42], "out": [43, 44] },
        ],
        "wires": [
            [4, 7],
            [11, 0],
            [10, 5],
            [11, 18],
            [10, 19],
            [13, 22],
            [12, 23],
            [9, 37],
            [39, 42],
            [3, 45],
            [8, 46],
            [38, 47],
            [43, 48],
            [44, 49, { "via": [[50, 690]] }],
            [27, 35],
            [26, 40],
            [28, 87],
            [34, 90],
            [34, 24],
            [28, 25],
            [27, 20],
            [26, 21],
            [12, 93],
            [13, 96],
            [97, 1],
            [94, 6],
            [91, 36],
            [88, 41],
            [98, 2],
            [98, 95],
            [98, 92],
            [98, 89],
            [98, 86],
        ],
    },

    LatchedAdder: {
        "v": 5,
        "in": [
            { "pos": [340, 570], "orient": "n", "id": 40, "name": "Reset", "val": 0, "isPushButton": true },
            { "pos": [40, 220], "id": 41, "val": 0 },
            { "pos": [40, 250], "id": 42, "val": 0 },
            { "pos": [40, 280], "id": 43, "val": 0 },
            { "pos": [40, 310], "id": 44, "val": 0 },
            { "pos": [280, 570], "orient": "n", "id": 45, "name": "Horloge", "val": 0, "isPushButton": true },
            { "pos": [190, 400], "orient": "n", "id": 60, "name": "Add./soustr.", "val": 0 },
        ],
        "out": [
            { "pos": [550, 380], "id": 61, "name": "V" },
            { "type": "display", "bits": 4, "pos": [100, 390], "orient": "s", "id": [50, 51, 52, 53], "name": "B" },
            { "type": "display", "bits": 4, "pos": [550, 230], "id": [46, 47, 48, 49], "name": "Acc." },
        ],
        "ic": [
            { "type": "alu", "pos": [180, 210], "in": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], "out": [10, 11, 12, 13, 14, 15] },
            { "type": "flipflop-d", "pos": [390, 100], "in": [17, 18, 19, 16], "out": [20, 21], "state": 0 },
            { "type": "flipflop-d", "pos": [390, 200], "in": [23, 24, 25, 22], "out": [26, 27], "state": 0 },
            { "type": "flipflop-d", "pos": [390, 300], "in": [29, 30, 31, 28], "out": [32, 33], "state": 0 },
            { "type": "flipflop-d", "pos": [390, 400], "in": [35, 36, 37, 34], "out": [38, 39], "state": 0 },
            { "type": "flipflop-d", "pos": [390, 500], "in": [55, 56, 57, 54], "out": [58, 59], "state": 0 },
        ],
        "wires": [
            [10, 16, { "via": [[260, 80]] }],
            [11, 22, { "via": [[260, 180]] }],
            [12, 28, { "via": [[260, 280]] }],
            [13, 34, { "via": [[260, 380]] }],
            [40, 37, { "via": [[340, 440]] }],
            [40, 31, { "via": [[340, 340, "n"]] }],
            [40, 25, { "via": [[340, 240, "n"]] }],
            [40, 19, { "via": [[340, 140, "n"]] }],
            [20, 0, { "via": [[430, 80], [430, 50], [130, 50], [130, 130]] }],
            [26, 1, { "via": [[440, 180], [440, 40], [120, 40], [120, 150]] }],
            [32, 2, { "via": [[450, 280], [450, 30], [110, 30], [110, 170]] }],
            [38, 3, { "via": [[460, 380, "n"], [460, 20], [100, 20], [100, 190]] }],
            [41, 4],
            [42, 5],
            [43, 6],
            [44, 7],
            [45, 35, { "via": [[280, 420]] }],
            [45, 29, { "via": [[280, 320, "n"]] }],
            [45, 23, { "via": [[280, 220, "n"]] }],
            [45, 17, { "via": [[280, 120, "n"]] }],
            [41, 50],
            [42, 51],
            [43, 52],
            [44, 53],
            [20, 46, { "via": [[480, 80]] }],
            [26, 47, { "via": [[480, 180]] }],
            [32, 48, { "via": [[480, 280]] }],
            [38, 49, { "via": [[480, 380]] }],
            [60, 8, { "via": [[230, 320, "n"], [230, 110, "n"]] }],
            [40, 57],
            [45, 55, { "via": [[280, 520]] }],
            [14, 54, { "via": [[260, 420, "s"], [260, 480]] }],
            [58, 61],
        ],
    },

    LatchedCounter: {
        "v": 5,
        "ic": [
            { "type": "flipflop-d", "pos": [170, 160], "in": [7, 8, 9, 6], "out": [10, 11], "state": 0, "trigger": "falling", "showContent": true },
            { "type": "flipflop-d", "pos": [300, 160], "in": [15, 16, 17, 14], "out": [18, 19], "state": 0, "trigger": "falling", "showContent": true },
            { "type": "flipflop-d", "pos": [440, 160], "in": [21, 22, 23, 20], "out": [24, 25], "state": 0, "trigger": "falling", "showContent": true },
            { "type": "flipflop-d", "pos": [580, 160], "in": [35, 36, 37, 34], "out": [38, 39], "state": 0, "trigger": "falling", "showContent": true },
        ],
        "in": [
            { "pos": [100, 180], "id": 12, "name": "Horloge", "val": 0, "isPushButton": true },
            { "pos": [100, 240], "id": 41, "name": "Reset", "val": 0, "isPushButton": true },
        ],
        "out": [{ "type": "display", "bits": 4, "pos": [660, 60], "id": [26, 27, 28, 29] }],
        "wires": [
            [12, 7],
            [10, 15],
            [18, 21],
            [19, 14],
            [11, 6],
            [25, 20],
            [10, 26, { "via": [[400, 30]] }],
            [18, 27, { "via": [[520, 50]] }],
            [24, 28],
            [39, 34],
            [24, 35],
            [38, 29],
            [41, 9],
            [41, 17],
            [41, 23],
            [41, 37],
        ],
    },

    Decoder2To4: {
        "v": 5,
        "in": [
            { "pos": [60, 80], "id": 0, "name": "S0", "val": 0 },
            { "pos": [60, 220], "id": 1, "name": "S1", "val": 0 },
        ],
        "gates": [
            { "type": "NOT", "pos": [130, 50], "in": 2, "out": 3 },
            { "type": "NOT", "pos": [130, 190], "in": 4, "out": 5 },
            { "type": "AND", "pos": [280, 60], "in": [6, 7], "out": 8 },
            { "type": "AND", "pos": [280, 120], "in": [9, 10], "out": 11 },
            { "type": "AND", "pos": [280, 180], "in": [12, 13], "out": 14 },
            { "type": "AND", "pos": [280, 240], "in": [15, 16], "out": 17 },
            { "type": "BUF", "pos": [130, 110], "in": 22, "out": 23 },
            { "type": "BUF", "pos": [130, 250], "in": 24, "out": 25 },
        ],
        "out": [
            { "pos": [370, 60], "id": 18, "name": "0" },
            { "pos": [370, 120], "id": 19, "name": "1" },
            { "pos": [370, 180], "id": 20, "name": "2" },
            { "pos": [370, 240], "id": 21, "name": "3" },
        ],
        "wires": [
            [0, 2],
            [1, 4],
            [11, 19],
            [17, 21],
            [14, 20],
            [8, 18],
            [3, 6],
            [5, 7],
            [5, 10],
            [3, 12],
            [0, 22],
            [1, 24],
            [23, 9],
            [23, 15],
            [25, 13],
            [25, 16],
        ],
    },

    Decoder3To8: {
        "v": 5,
        "in": [
            { "pos": [60, 130], "id": 26, "name": "S0", "val": 1 },
            { "pos": [60, 290], "id": 27, "name": "S1", "val": 1 },
            { "pos": [60, 460], "id": 28, "name": "S2", "val": 0 },
        ],
        "gates": [
            { "type": "NOT", "pos": [130, 90], "in": 30, "out": 31 },
            { "type": "NOT", "pos": [130, 250], "in": 32, "out": 33 },
            { "type": "NOT", "pos": [130, 420], "in": 34, "out": 35 },
            { "type": "AND", "bits": 3, "pos": [340, 120], "in": [36, 37, 38], "out": 39 },
            { "type": "AND", "bits": 3, "pos": [340, 190], "in": [40, 41, 42], "out": 43 },
            { "type": "AND", "bits": 3, "pos": [340, 260], "in": [44, 45, 46], "out": 47 },
            { "type": "AND", "bits": 3, "pos": [340, 330], "in": [48, 49, 50], "out": 51 },
            { "type": "AND", "bits": 3, "pos": [340, 400], "in": [52, 53, 54], "out": 55 },
            { "type": "AND", "bits": 3, "pos": [340, 470], "in": [56, 57, 58], "out": 59 },
            { "type": "AND", "bits": 3, "pos": [340, 540], "in": [60, 61, 62], "out": 63 },
            { "type": "AND", "bits": 3, "pos": [340, 50], "in": [64, 65, 66], "out": 67 },
            { "type": "BUF", "pos": [130, 170], "in": 76, "out": 77 },
            { "type": "BUF", "pos": [130, 330], "in": 78, "out": 79 },
            { "type": "BUF", "pos": [130, 500], "in": 80, "out": 81 },
        ],
        "out": [
            { "pos": [420, 50], "id": 68, "name": "0" },
            { "pos": [420, 120], "id": 69, "name": "1" },
            { "pos": [420, 190], "id": 70, "name": "2" },
            { "pos": [420, 260], "id": 71, "name": "3" },
            { "pos": [420, 330], "id": 72, "name": "4" },
            { "pos": [420, 400], "id": 73, "name": "5" },
            { "pos": [420, 470], "id": 74, "name": "6" },
            { "pos": [420, 540], "id": 75, "name": "7" },
        ],
        "wires": [
            [26, 30],
            [27, 32],
            [28, 34],
            [67, 68],
            [39, 69],
            [43, 70],
            [47, 71],
            [51, 72],
            [55, 73],
            [59, 74],
            [63, 75],
            [31, 64],
            [33, 65],
            [35, 66],
            [26, 76],
            [27, 78],
            [28, 80],
            [77, 36],
            [33, 37],
            [35, 38],
            [31, 40],
            [79, 41],
            [35, 42],
            [35, 46],
            [79, 45],
            [77, 44],
            [31, 48],
            [77, 52],
            [31, 56],
            [77, 60],
            [33, 49],
            [33, 53],
            [79, 57],
            [79, 61],
            [81, 50],
            [81, 54],
            [81, 58],
            [81, 62],
        ],
    },

    ParityGenerator: {
        "v": 5,
        "in": [
            { "pos": [70, 180], "id": 0, "name": "D1", "val": 0 },
            { "pos": [70, 300], "id": 1, "name": "D2", "val": 0 },
            { "pos": [70, 360], "id": 2, "name": "D3", "val": 0 },
            { "pos": [70, 420], "id": 3, "name": "D4", "val": 0 },
        ],
        "out": [
            { "pos": [530, 60], "id": 4, "name": "P1" },
            { "pos": [530, 120], "id": 5, "name": "P2" },
            { "pos": [530, 180], "id": 6, "name": "D1" },
            { "pos": [530, 240], "id": 7, "name": "P3" },
            { "pos": [530, 300], "id": 8, "name": "D2" },
            { "pos": [530, 360], "id": 9, "name": "D3" },
            { "pos": [530, 420], "id": 10, "name": "D4" },
        ],
        "gates": [
            { "type": "XOR", "pos": [460, 240], "in": [11, 12], "out": 13 },
            { "type": "XOR", "pos": [380, 230], "in": [14, 15], "out": 16 },
            { "type": "XOR", "pos": [340, 120], "in": [17, 18], "out": 19 },
            { "type": "XOR", "pos": [260, 110], "in": [20, 21], "out": 22 },
            { "type": "XOR", "pos": [300, 60], "in": [23, 24], "out": 25 },
            { "type": "XOR", "pos": [220, 40], "in": [26, 27], "out": 28 },
        ],
        "wires": [
            [3, 10],
            [2, 9],
            [1, 8],
            [0, 6],
            [22, 17],
            [16, 11],
            [28, 23],
            [25, 4],
            [19, 5],
            [13, 7],
            [3, 18, { "via": [[280, 420], [280, 130]] }],
            [3, 12, { "via": [[420, 420]] }],
            [2, 15, { "via": [[340, 360]] }],
            [1, 14, { "via": [[310, 300], [310, 220]] }],
            [2, 21, { "via": [[220, 360]] }],
            [0, 20, { "via": [[190, 180], [190, 100]] }],
            [3, 24, { "via": [[150, 420], [150, 70]] }],
            [1, 27, { "via": [[120, 300], [120, 50]] }],
            [0, 26, { "via": [[100, 30]] }],
        ],
    },

    ShiftRegister: {
        "v": 5,
        "in": [
            { "pos": [110, 130], "id": 0, "name": "D", "val": 1 },
            { "pos": [100, 210], "id": 1, "name": "Horloge", "val": 0, "isPushButton": true },
            { "pos": [100, 260], "id": 38, "name": "Reset", "val": 0, "isPushButton": true },
        ],
        "out": [{ "type": "display", "bits": 4, "pos": [650, 60], "id": [39, 40, 41, 42] }],
        "ic": [
            { "type": "flipflop-d", "pos": [560, 150], "in": [21, 22, 23, 20], "out": [24, 25], "state": 1, "showContent": true },
            { "type": "flipflop-d", "pos": [450, 150], "in": [15, 16, 17, 14], "out": [18, 19], "state": 0, "showContent": true },
            { "type": "flipflop-d", "pos": [340, 150], "in": [9, 10, 11, 8], "out": [12, 13], "state": 1, "showContent": true },
            { "type": "flipflop-d", "pos": [230, 150], "in": [3, 4, 5, 2], "out": [6, 7], "state": 1, "showContent": true },
        ],
        "wires": [
            [6, 8],
            [12, 14],
            [18, 20],
            [38, 5, { "via": [[230, 260]] }],
            [38, 11, { "via": [[340, 260]] }],
            [38, 17, { "via": [[450, 260]] }],
            [38, 23, { "via": [[560, 260]] }],
            [1, 21, { "via": [[490, 210]] }],
            [1, 15, { "via": [[380, 210]] }],
            [1, 9, { "via": [[270, 210]] }],
            [1, 3, { "via": [[160, 210]] }],
            [0, 2],
            [24, 39],
            [18, 40, { "via": [[530, 50]] }],
            [12, 41, { "via": [[410, 70]] }],
            [6, 42, { "via": [[300, 90]] }],
        ],
    },

    Muxes: {
        "v": 5,
        "in": [
            { "pos": [160, 70], "orient": "s", "id": 59, "name": "S0", "val": 0 },
            { "pos": [160, 340], "orient": "s", "id": 64, "name": "S0", "val": 0 },
            { "pos": [450, 360], "orient": "s", "id": 69, "name": "S0", "val": 0 },
            { "bits": 4, "pos": [270, 60], "orient": "s", "id": [0, 1, 2, 3], "val": [1, 1, 0, 0] },
            { "bits": 4, "pos": [460, 70], "orient": "s", "id": [60, 61, 62, 63], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [270, 300], "orient": "s", "id": [65, 66, 67, 68], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [110, 180], "id": [86, 87, 88, 89], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [230, 180], "id": [90, 91, 92, 93], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [400, 170], "id": [94, 95, 96, 97], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [400, 250], "id": [98, 99, 100, 101], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [110, 420], "id": [102, 103, 104, 105], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [110, 520], "id": [106, 107, 108, 109], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [400, 540], "id": [110, 111, 112, 113], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [400, 440], "id": [114, 115, 116, 117], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [230, 400], "id": [118, 119, 120, 121], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [230, 460], "id": [122, 123, 124, 125], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [230, 520], "id": [126, 127, 128, 129], "val": [0, 0, 0, 0] },
            { "bits": 4, "pos": [230, 580], "id": [130, 131, 132, 133], "val": [0, 0, 0, 0] },
        ],
        "ic": [
            { "type": "mux", "from": 2, "to": 1, "pos": [160, 160], "in": [5, 6, 7], "out": 8 },
            { "type": "mux", "from": 4, "to": 1, "pos": [290, 180], "in": [4, 9, 10, 11, 12, 13], "out": 14 },
            { "type": "mux", "from": 8, "to": 1, "pos": [470, 210], "in": [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], "out": 26 },
            { "type": "mux", "from": 4, "to": 2, "pos": [160, 470], "in": [27, 28, 29, 30, 31], "out": [32, 33] },
            { "type": "mux", "from": 8, "to": 2, "pos": [290, 490], "in": [34, 35, 36, 37, 38, 39, 40, 41, 42, 43], "out": [44, 45] },
            { "type": "mux", "from": 8, "to": 4, "pos": [450, 490], "in": [46, 47, 48, 49, 50, 51, 52, 53, 54], "out": [55, 56, 57, 58] },
            { "type": "alu", "pos": [630, 450], "in": [70, 71, 72, 73, 74, 75, 76, 77, 78, 79], "out": [80, 81, 82, 83, 84, 85] },
        ],
        "wires": [
            [59, 7],
            [0, 12],
            [1, 13],
            [60, 23],
            [61, 24],
            [62, 25],
            [64, 31],
            [65, 42],
            [66, 43],
            [69, 54],
            [86, 5],
            [87, 6],
            [90, 4],
            [91, 9],
            [92, 10],
            [93, 11],
            [94, 15],
            [95, 16],
            [96, 17],
            [97, 18],
            [98, 19],
            [99, 20],
            [100, 21],
            [101, 22],
            [104, 27],
            [105, 28],
            [106, 29],
            [107, 30],
            [110, 50],
            [111, 51],
            [112, 52],
            [113, 53],
            [114, 46],
            [115, 47],
            [116, 48],
            [117, 49],
            [119, 34],
            [120, 35],
            [123, 36],
            [124, 37],
            [127, 38],
            [128, 39],
            [131, 40],
            [132, 41],
        ],
    },
}
